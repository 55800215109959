<template>
  <div class="disclosure">
    <h1>VW ID.4 Charging Plan</h1>
    <h2>Promotion Terms and Conditions</h2>
    <p class="primary-color"><strong>Effective as of February 2024</strong></p>
    <p>
      Electrify America, LLC (“Electrify America”) and Volkswagen (“Volkswagen”) are pleased to provide the VW ID.4
      Charging Plan (“Plan”) to drivers of new 2024 Volkswagen ID.4 vehicles purchased from an authorized Volkswagen
      retailer. This Promotion provides 3 years of Pass + pricing with 500 kWh of complimentary DC fast (CCS) and Level
      2 charging. The Promotion starts from the date of vehicle purchase, of which is provided to Electrify America by
      your vehicle’s manufacturer. By clicking “Agree” below, you agree to participate in this Plan, subject to your
      compliance with all Electrify America terms applicable to the Plan (including the terms contained in this
      disclosure and the app’s Terms of Use, which together govern your use of the Plan).
    </p>

    <p>
      Participation requires an Electrify America account, the Electrify America mobile app, enrollment in the VW ID.4
      Charging Plan and acceptance of Electrify America’s
      <router-link class="link" :to="{ name: 'terms-en' }">Terms of Use</router-link> and
      <router-link class="link" :to="{ name: 'privacy-en' }">Privacy Policy</router-link>. Your mobile carrier’s normal
      messaging, data, and other rates and fees will apply to your use of the Electrify America app. You may also be
      required to use a credit or debit card to load dollars to an account for idle fees or other charging sessions not
      covered by your plan and you agree to an automatic reload feature for the payment method used by the app. See the
      Electrify America Terms of Use available at
      <router-link class="link" :to="{ name: 'terms-en' }">https://www.electrifyamerica.com/terms</router-link> for
      further details.
    </p>
    <p>
      Immediately upon your registration and acceptance of these terms, you will be enrolled in a program that provides
      500 kWh of DC fast (CCS) charging for your 2024 ID.4, compliments of Volkswagen. You may redeem this credit on
      Electrify America’s network of electric vehicle charging stations.
    </p>
    <p>
      After signing up, you can redeem your complimentary 3 years of Pass+ pricing with 500 kWh of complimentary DC fast
      (CCS) and Level 2 charging during your initiation of a charging session at any Electrify America charging station,
      exclusively by using the Electrify America app “Swipe to start” or NFC Pass feature with the appropriate plan
      selected. This complimentary charging may not be initiated using the interface screen or buttons on the charger.
      Plug&Charge functionality will also be available for use with this plan. Plug&Charge can help ID.4 owners have a
      better charging experience by creating a secure, convenient, and effortless way to pay for charging sessions on
      the Electrify America network.
    </p>
    <p>
      With the exclusion of pre-funding per our
      <router-link class="link" :to="{ name: 'terms-en' }">Terms of Use</router-link>, there is no additional fee or
      purchase required for your access to this promotion. After the 500 kWh credit is fully expended, you are
      responsible for all charging fees (including any applicable tax and idle fees) under your Pass + plan.
    </p>
    <p class="primary-color">
      <strong>PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PROMOTION:</strong>
    </p>
    <p>
      You may <strong>only</strong> use the VW ID.4 Charging Plan for charging the Volkswagen ID.4 whose VIN you
      submitted when signing up for this promotion on your Electrify America account.
      <strong>No other vehicle is eligible to receive this credit through your account</strong>, whether owned by you or
      others. This benefit is for first owners only.
    </p>
    <p>
      The complimentary 500 kWh charging <strong>does not include</strong> any idle fees and their associated applicable
      taxes assessed at the charging station. You will be responsible for paying such fees through your account if you
      incur them during a charging session.
    </p>
    <p>
      You must follow all product, vehicle, safety, and technical documentation included with the Volkswagen ID.4 or
      when charging at an Electrify America charging station
    </p>
    <p>You must use the correct connector type for the Volkswagen ID.4 at the charging station.</p>
    <p>
      Once the 500 kWh credit expires, you will not be able to reload or add more credit to this promotional plan. At
      that time, your account will automatically convert to a basic Electrify America “Pass +” account, at no additional
      fee or charge to you (neither one-time nor recurring). You may, but are not obligated to, sign up for a different
      type of subscription via the Electrify America mobile app. Please refer to the information on the
      <strong class="primary-color">“Plan Details”</strong> section of the app to keep track of the amount of time
      remaining in this promotion.
    </p>
    <p>
      This credit is not returnable or redeemable for any cash value, and may not be sold, loaned, or otherwise
      distributed to any third party for any reason. You may not transfer any portion of the credit to any other
      Electrify America account. This complimentary charging is for your personal use only, and the plan is not
      authorized, intended, or available for any commercial use (such as ride-hailing and ridesharing).
    </p>
    <p>
      Please read these Terms of Use documents carefully for information about how you can pay through your account for
      idle fees and applicable taxes as well as for charging sessions outside of the complimentary charging offered
      under this promotion.
    </p>
    <p>
      You agree to comply with all applicable laws and regulations when using Electrify America services and when
      charging your vehicle at Electrify America charging stations.
    </p>
    <p>
      Electrify America reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
      portion of this promotion, without notice, if Electrify America determines or suspects, in its sole discretion,
      that you: (a) are in violation of the Electrify America Terms of Use, these Promotion Terms and Conditions, or any
      other contract between you and Electrify America; (b) have engaged in charging sessions that are excessive,
      fraudulent, or otherwise indicate an unauthorized or unintended use of this complimentary promotion; or (c) have
      engaged in any illegal, fraudulent, tortious, injurious, harmful, or abusive conduct in your use of the Electrify
      America services, the Electrify America mobile app, or an Electrify America charging station.
    </p>
    <p>
      Electrify America reserves the right to end this promotion at any time before its automatic termination date for
      any reason, in its sole discretion, provided that Electrify America will send an email to the address associated
      with your account in the event of any change to the scheduled end date of this promotion. Following the
      termination or expiration of this program for any reason, your access to this promotion will immediately cease,
      and you may not use or redeem any remaining portion of the complimentary charging.
    </p>
  </div>
</template>

<script>
export default {
  name: '2024-vw-id4-charging-plan-disclosure',
  metaInfo: {
    title: '2024 VW ID.4 Charging Plan Disclosure | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/vw-id4-charging-plan-disclosure/' }],
  },
};
</script>
